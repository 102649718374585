/*
/  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -
/
/  dependencias
/
/  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -
*/
import {isString, intersection} from "lodash";
//import { "d3-format" } from "d3";

const d3 = Object.assign({}, require("d3-format"), require('d3-scale'));

/*
/  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -
/
/  const
/
/  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -
*/
const colors  = ["#DC1F3B", "#72C3DE", "#98CA8D", "#ED6F30", "orange", "pink", "brown", "white", "black", "purple"];
const fields  = ["LISTA_NOMINAL", "NUM_VOTOS_NULOS", "TOTAL_VOTOS"];
export const expenseList = ["diferencia_por_prorrateo", "egresos_por_transferencias_a_los_candidatos_federales", "egresos_por_transferencias_a_los_candidatos_locales",
"financieros", "operativos_de_la_precampana", "produccion_de_los_mensajes_para_radio_y_tv", "propaganda",
"propaganda_en_diarios_revistas_y_otros_medios_impresos", "propaganda_en_via_publica", "propaganda_exhibida_en_paginas_de_internet",
"propaganda_exhibida_en_salas_de_cine", "propaganda_utilitaria", "tope_de_gastos", "total_gastos"];
export const incomeList = ["aportaciones_de_candidato", "aportaciones_de_candidato_independiente", "aportaciones_de_simpatizantes",
"aportaciones_del_aspirante", "aportaciones_del_precandidato", "aportanciones_de_militantes", "autofinanciamiento",
"financiamiento_publico", "ingresos_por_transferencias_en_especie", "otros_ingresos", "rendimientos_bancarios",
"rendimientos_financieros_fondos_fideicomisos", "transferencias_de_candidatos_rp_federales", 
"transferencias_de_candidatos_rp_locales", "transferencias_de_recursos_federales", "transferencias_de_recursos_locales",  "total_ingresos"]

/*
/  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -
/
/  common methods
/
/  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -
*/
const frm = d3.format(",");
export const reducer       = (accumulator = 0, currentValue = 0) => +accumulator + +currentValue;
export const percent       = num => Number.isInteger(+num) ? num * 100 : +(num * 100).toFixed(2);
export const format        = num => frm(+num) //Number.isInteger(+num) ? frm(num) : + frm(num); 
export const sum           = (items, index) => items.map(d => +d[index]).reduce(reducer);

// esta función todavía hace feos
export const scale         = (item, parties, type, value) => {
  const ids    = parties.map(d => d.id);
  const values = ids.map(d => +item[d + type]); 
  const funct  = d3.scaleLinear().domain(values).range([0, 100])
  return funct(value)
};

/*
/  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -
/
/  data sort/filter methods
/
/  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -
*/

// Columnas válidas:
// regresa solo las columnas con votos de la db principal
//
export const validParties  = (parties, data) => parties.filter(d => data.map(e => +e[d.id]).reduce(reducer));

// Partidos válidos
// usando los resultados generales y la db, genera la lista
// de fórulas únicas válidas
//


export const validFormulas = (resumen, parties) => {

  let keys1    = Object.keys(resumen);
  let keys2    = parties.map(d => d.id),
      all      = keys1.filter( d => keys2.includes(d) || d.search("C_COMUN_") != -1),
      filtered = all.filter(d => sum([resumen], d) )
  
  if(!filtered.length) {
    //keys1      = keys1.map(d => d.replace("_CAND", "")); // ugly hack
    all          = keys1.filter( d => keys2.filter(e => d.search(e) != -1 ).length || d.search("C_COMUN_") != -1 );
    filtered     = all.filter(d => sum([resumen], d) ).filter( d => {
      return d != "ID_ESTADO" && d != "LISTA_NOMINAL" && d != "SECCIONES"
    });

    console.log("filtered:", filtered);
  }  
  
  

  return filtered.map( (d, i) => makeFormulaObject(parties, d, i));
}

// Datos por casilla
// genera los datos para la tabla de casillas
//
export const getDataByCasilla = (data, parties) => {
  
  return data.map( d => {
    let rows     = parties.concat(fields),
        response = {casilla : d.CASILLA, seccion : d.SECCION, acta : d.RUTA_ACTA},
        party,
        winner;
    
    for(party of rows){
      let total = isString(party) ? +d[party] : sumItems([d], party);
      let label = isString(party) ? party : party.id;
      response[label] = total;
    }

    winner = parties.map(p => ({id : p.id, val : response[p.id]}) )
                    .sort( (a,b) => a.val > b.val ? -1 : 1  )[0].id
    
    response.winner = winner;

    response.participacion = percent(response.TOTAL_VOTOS / response.LISTA_NOMINAL);
    return response;
  })
}

// Datos por sección
// genera los datos para la tabla por sección 
//
export const getDataBySection = (data, parties, sections) => {
  return sections.map(section => {
    let items    = data.filter(d => d.SECCION == section),
        rows     = parties.concat(fields),
        response = {section, casillas : items.length},
        party;

    for(party of rows){
      let total = sumItems(items, party);
      let label = isString(party) ? party : party.id;
      response[label] = total;
    }

    response.winner = parties.map(p => ({id : p.id, val : response[p.id]}) ).sort( (a,b) => a.val > b.val ? -1 : 1  )[0].id;
    response.participacion = percent(response.TOTAL_VOTOS / response.LISTA_NOMINAL);
    return response;
  });
}


// Organiza los datos de gastos
//
export const parseExpenses = (expenses, formulas) => {
  let newExpenses = expenses.map( expense => {
    let newExpense             =  {}//Object.assign({}, expense);
    newExpense.ambito          = expense.ambito;
    newExpense.nombre          = expense.nombre_completo;
    newExpense.sujeto_obligado = expense.sujeto_obligado;
    newExpense.tipo_de_proceso = expense.tipo_de_proceso;
    newExpense.siglas          = expense.siglas.split("_");
    newExpense.formula         = findParty(newExpense, formulas);//formulas.find( d => intersection(newExpense.siglas, d.parties).length )
    newExpense.subnivel        = expense.subnivel_entidad;
    newExpense.items           = {} //expenseList.map(d => ({[d] : expense[d]}));
    for(let exp of expenseList){
      newExpense.items[exp] = expense[exp]
    }

    return newExpense;
  });
  return newExpenses;
}

export const agregateExpenses = expenses => {
  const names    = [...new Set(expenses.map(d => d.nombre))];
  const response = names.map(name => {
    let items  = expenses.filter(d => d.nombre == name);
    let man    = Object.assign({}, items[0]);
    man.siglas = items.map(d => d.siglas).flat();    
    
    for(let exp of expenseList){
      //newExpense.items[exp] = expense[exp]
      man.items[exp] = items.map(d => d.items[exp]).reduce(reducer);
    }
   
    return man;
  })

  return response;
}

// Organiza los datos de ingresos
//
export const parseIncome = Income => {
  let newIncome = Income.map( income => {
    let newincome             =  {}//Object.assign({}, income);
    newincome.ambito          = income.ambito;
    newincome.nombre          = income.nombre_completo;
    newincome.sujeto_obligado = income.sujeto_obligado;
    newincome.tipo_de_proceso = income.tipo_de_proceso;
    newincome.siglas          = income.siglas.split("_");
    newincome.subnivel        = income.subnivel_entidad;
    newincome.items           = {};//incomeList.map(d => ({[d] : income[d]}));

    for(let inc of incomeList){
      newincome.items[inc] = income[inc]
    }

    return newincome;
  });

  return newIncome;
}

export const agregateIncome = income => {
  const names    = [...new Set(income.map(d => d.nombre))];
  const response = names.map(name => {
    let items  = income.filter(d => d.nombre == name);
    let man    = Object.assign({}, items[0]);
    man.siglas = items.map(d => d.siglas).flat();    
    
    for(let inc of incomeList){
      //newincense.items[inc] = incense[inc]
      man.items[inc] = items.map(d => d.items[inc]).reduce(reducer);
    }
   
    return man;
  })

  return response;
}

// Organiza los datos de eventos
//
export const parseEvents = (events, expenses) => {
  let newEvents = events.map( event => {
    let newEvent = {};
    let expense  = expenses.find(d => event.nombre_completo == d.nombre);
    newEvent.descripcion = event.descripcion_del_evento;
    newEvent.distrito = event.distrito_del_evento.split("-")[0];
    newEvent.tipo = event.evento;
    newEvent._fecha = new Date(event.fecha_evento)
    newEvent.fecha = `${newEvent._fecha.getDate()}-${newEvent._fecha.getMonth()+1}-${newEvent._fecha.getFullYear()}`;
    newEvent.inicio = event.hora_inicio.substring(0,5);
    newEvent.fin = event.hora_fin.substring(0,5);
    newEvent.lugar = event.lugar_exacto_del_evento;
    newEvent.nombre = event.nombre_completo;
    newEvent.siglas = expense ? expense.siglas : [];
    newEvent.sujeto = event.sujeto_obligado;
    newEvent.evento = event.nombre_del_evento;
    newEvent.tipo_de_proceso = event.tipo_de_proceso;

    return newEvent;
  });


  return newEvents;
}

/*
/  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -
/
/  private methods
/
/  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -
*/
const findParty = (item, formulas) => {
  return  formulas.find( d => intersection(item.siglas, d.parties).length || d.name == item.siglas )
}

const sumItems = (items, party) => {
  if(isString(party)){
    return items.map(d => d[party]).reduce(reducer);
  }
  else{
    let res =  items.map(item => {
      let labels = Object.keys(item).filter(label => party.parties.includes(label) || intersection(party.parties, label.split("_")).length )
      return labels.map(label => +item[label]).filter(d => d);
    }).flat()//.reduce(reducer);

    return res.length ? res.reduce(reducer) : 0;
  }
}

const makeFormulaObject = (parties, formula, index) => {
  let party = parties.find(d => d.id == formula),
      txt,
      list,
      name
  
  if(party){
    party.color = assignColor(index);
    return party;
  }

  else{
    txt  = formula.replace("C_COMUN_", "");
    list = txt.split("_");
    name = list.map(p => {
      let party = parties.find(e => e.id == p);
      return party ? party.name : p;
    }).join(", ");
    return {
      id : formula,
      parties : list,
      name,
      color : assignColor(index)
    }
  }
}

const assignColor = index => colors[index];
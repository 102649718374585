<!--
  ////////////////////////////////////////////////////////////////////////////////
  //
  // ⭐ DEFINE EL COMPONENTE DE VUE
  //
  ////////////////////////////////////////////////////////////////////////////////
-->
<script>
/*
/  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -
/
/  dependencias
/
/  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -
*/
import { ref, onMounted, watch, computed } from "vue";
import L from "leaflet";
import chroma from "chroma-js";
import iconUrl from "leaflet/dist/images/marker-icon.png";
import shadowUrl from "leaflet/dist/images/marker-shadow.png";
import iconRetinaUrl from "leaflet/dist/images/marker-icon-2x.png";

import { percent} from "@/common-library"

/*
/  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -
/
/  fix leaflet icons
/
/  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -
*/
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl,
  iconUrl,
  shadowUrl,
});

/*
/  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -
/
/  setup
/
/  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -
*/
const colorGrey = { color: "grey", fillOpacity: 0.7, weight: 1 };
const winnerColor = (w) => ({
  fillColor: w.color,
  color: "rgba(255,255,255,0.7)",
  fillOpacity: 0.7,
  weight: 1
});
const mapID        = "the-map-o";
const tileServer   = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
const mapAttr      = '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors';
const initialPoint = [19.2781705, -98.4420179];
const colorScale   = ['#fafa6e','#2A4858'];
const initialZoom  = 13;
const tileLayer    = ref(null);
const geosections  = ref(null);
const colorRange   = ref(5);
const selected     = ref("");
const sections     = ref(null);
let map          = null;//ref(null);
const section      = ref("");

/*
/  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -
/
/  tooltip
/
/  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -
*/

const tooltip = (item, parties) => {
  const title = `Sección <strong>${item.section}</strong>`;
  const percentbar = percent(item['TOTAL_VOTOS']/item.LISTA_NOMINAL);
  const partyData = parties
    .map((p) => `<div class="row"><div class="col-8">${p.parties.join(", ")}</div> <div class="col-4 text-right">${item[p.id]}</div></div>`)
    .join("");

  return `<div class="if_tooltip">
    <h4>${title}</h4>
    
    <div class="if_results">
      ${partyData}
      <div class="row"><div class="col-8">Votos nulos</div> <div class="col-4 text-right" >${item.NUM_VOTOS_NULOS}</div></div>
    </div>
    <div class="if_results_total">
      <div class="row"><div class="col-7"><strong>Total votos</strong></div> <div class="col-5 text-right"><strong>${item.TOTAL_VOTOS}</strong></div></div>
        <div class="row mt-1"><div class="col-7">Participación</div> <div class="col-5 text-right"> ${percentbar}%</div></div>
        <div class="el_conteiner_graph"><div class="el_table_graph" style="width:${percentbar}%"></div></div>
        <div class="row"><div class="col-7">Lista nominal</div> <div class="col-5 text-right"> ${item.LISTA_NOMINAL}</div></div>
    </div>

  </div>`;
};

/*
/  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -
/
/  VUE CODE
/
/  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -
*/
export default {
  /*
  /  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -
  /
  /  props
  /
  /  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -
  */
  props: ["geojson", "items", "order", "type", "formulas"],
  
  /*
  /  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -
  /
  /  setup
  /
  /  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -
  */
  setup(props) {
    sections.value = props.geojson.map((d) => d.geo);

    // MOUNTED
    // -  -  -  -  -  -  -  -  -  -  -  -  -  -  -
    //
    onMounted(() => {
      buildMainMap();
    });


    // COMPUTED
    // -  -  -  -  -  -  -  -  -  -  -  -  -  -  -
    //
    const limits = computed( () => {
      if(!selected.value){
        return {};
      }
      
      return {
        values : chroma.limits(props.items.map(d => +d[selected.value]), 'q', colorRange.value)
                       .map( d => Number.isInteger(d) ? +d : +d.toFixed(2)),
        colors : chroma.scale(colorScale).mode('lch').colors(colorRange.value)
      }
    });

    // METHODS
    // -  -  -  -  -  -  -  -  -  -  -  -  -  -  -
    //
    const buildMainMap = () => {
      map = L.map(mapID, { zoomControl: true }).setView(
        initialPoint,
        initialZoom
      );
      tileLayer.value = L.tileLayer(tileServer, { attribution: mapAttr }).addTo(
        map
      );
      drawSections();
    }

    const drawSections = () => {
      geosections.value = L.geoJSON(sections.value)
        .bindTooltip((layer) => {
          let section = layer.feature.properties.seccion,
            item = props.items.find((d) => d.section == section);

          return tooltip(item, props.formulas);
        })
        .addTo(map);

      setWinnerColors();
      map.scrollWheelZoom.disable();
      console.log("sections bounds:", geosections.value.getBounds());
      console.log("map:",  map);
      try{
        map.fitBounds(geosections.value.getBounds());
      }
      catch(e){
        console.log(e);
      }
    }

    const drawSection = () => {
      const item = sections.value.find( d => {
        return d.properties.seccion == section.value;
      })

      geosections.value = L.geoJSON(item)
        .bindTooltip((layer) => {
          let section = layer.feature.properties.seccion,
            item = props.items.find((d) => d.section == section);

          return tooltip(item, props.formulas);
        })
        .addTo(map);
      
      setWinnerColors();
      map.scrollWheelZoom.disable();

      console.log("section bounds:", geosections.value.getBounds());
      console.log("map:",  map);
      try{
        map.fitBounds(geosections.value.getBounds());
      }
      catch(e){
        console.log(e);
      }
    }

    const updateMap = () => {
      map.removeLayer( geosections.value  ) ;
      selected.value = "";

      if(section.value){
        try{
          drawSection();
        }
        catch(e){
          console.log(e);
        }
        
      }
      else{
        try{
          drawSections();
        }
        catch(e){
          console.log(e);
        }
      }
    }

    const updateColors = newVal => {
      if(!newVal){
        setWinnerColors();
      }
      else{
        geosections.value.eachLayer( layer => {
          let data = props.items.find(d => d.section == layer.feature.properties.seccion);

          if(!data) return;

          for(let i = 0; i <= limits.value.colors.length; i++){
            if(data[selected.value] <= limits.value.values[i+1]){
              layer.setStyle({fillColor: limits.value.colors[i]});
              break;
            }
          }
        });
      }
    }

    const setWinnerColors = () => {
      geosections.value.eachLayer((layer) => {
        let data = props.items.find((d) => d.section == layer.feature.properties.seccion),
        winner;
        if (!data) {
          layer.setStyle(colorGrey);
        } else {
          winner = props.formulas.find((d) => d.id == data.winner);
          layer.setStyle(winnerColor(winner));
        }
      });
    };

    // WATCH
    // -  -  -  -  -  -  -  -  -  -  -  -  -  -  -
    //
    watch( selected, newVal => {
      updateColors(newVal);
    });

    watch(colorRange, newVal => {
      updateColors(newVal);
    });

    watch( section, () => {
      updateMap();
    })

    // CONFIG DATA
    // -  -  -  -  -  -  -  -  -  -  -  -  -  -  -
    //
    return {
      // const
      mapID,
      // data
      map,
      sections,
      section,
      tileLayer,
      selected,
      colorRange,
      // computed
      limits
    };
  },
};
</script>
<template>
  <div>
    <!-- ETIQUETAS -->
    

    <div class="el_container_map">
      <div class="container pt-3" v-if="!section">
        <div class="col-sm-3">
          <form class="el_filters">
            <p>
              Mostrar:
              <select v-model="selected">
                <option value="">Fórmula ganadora</option>
                <option value="TOTAL_VOTOS">Total de votos</option>
                <option value="TOTAL_VOTOS_percent">
                  Porcentaje de participación
                </option>
                <option value="LISTA_NOMINAL">Lista nominal</option>
                <option value="NUM_VOTOS_NULOS">Votos nulos</option>
              </select>
            </p>
          </form>
          <form class="el_filters">
          <p>
            Mostrar sección:
            <select v-model="section">
              <option value="">Selecciona una sección</option>
              <option v-for="(item, i) in items" :value="item.section" :key="`sec-${i}`">{{item.section}}</option>
            </select>
          </p>
        </form>
        </div>
        <div class="col-sm-3 offset-sm-6">
          <div class="el_labels">
            <p>
              <span
                v-for="(formula, i) in formulas"
                :key="i + '-formula-label'"
                class="el_label_content"
              >
                <span
                  :style="{
                    backgroundColor: formula.color,
                    display: 'inline-block',
                    width: '1em',
                    height: '1em',
                  }"
                ></span>
                {{ formula.name }}
              </span>
            </p>
          </div>
        </div>
      </div>
      <div v-if="selected">
        <form class="el_filters_guide">
          <div class="row">
            <div class="col-sm-2">
              <p>
                Segmentos
                <select v-model="colorRange">
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                </select>
              </p>
            </div>
            <div class="col">
              <ul class="el_colorguide mt-3" v-if="selected">
                <li v-for="(color, i) in limits.colors" :key="`${i}-color`">
                  <span
                    :style="{
                      display: 'inline-block',
                      background: color,
                      height: '1em',
                      width: '110%',
                    }"
                    class="el_colorguide_color"
                  ></span>
                  <span class="el_colorguide_text">
                    {{
                      `${limits.values[i]}${
                        selected == "TOTAL_VOTOS_percent" ? "%" : ""
                      }`
                    }}
                    -
                    {{
                      `${limits.values[i + 1]}${
                        selected == "TOTAL_VOTOS_percent" ? "%" : ""
                      }`
                    }}</span
                  >
                </li>
              </ul>
            </div>
          </div>
        </form>
      </div>
      
      <div :id="mapID" class="el_map"></div>
      
    </div>
  </div>

  
</template>

<!--
  ////////////////////////////////////////////////////////////////////////////////
  //
  // ⭐ DEFINE EL ESTILO DEL COMPONENTE DE VUE
  //
  ////////////////////////////////////////////////////////////////////////////////
-->
<style>
@import "../../node_modules/leaflet/dist/leaflet.css";
#the-map-o {
  height: 600px;
}
</style>
<script>
export default {
  props : ["parties", "data", "resumen"]
}
</script>
<template>
  <table class="table">
      <thead>
        <tr>
          <th>sección</th>
          <th>casillas</th>
          <th v-for="title in parties" :key="title.name">{{title.name}}</th>
          <th>lista nominal</th>
          <th>votos nulos</th>
          <th>total de votos</th>
          <th>participación</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, i) in data" :key="i">
          <td><h4>{{item.section}}</h4></td>
          <td>{{item.casillas}}</td>
          <td v-for="(formula, j) in parties" :key="j+','+i">
            {{item[formula.id]}}
          </td> 
          <td>{{item.LISTA_NOMINAL}}</td> 
          <td>{{item.NUM_VOTOS_NULOS}}</td> 
          <td>{{item.TOTAL_VOTOS}}</td>
          <td>{{item.participacion}}%</td>
        </tr>

        <tr>
          <td>{{resumen.SECCIONES}}</td>
          <td>{{resumen.CASILLAS}}</td>

          <td v-for="(party, j) in parties" :key="j+','+i">
            {{ resumen[party.id] }}
          </td>

          <td>{{resumen.LISTA_NOMINAL}}</td>
          <td>{{resumen.NUM_VOTOS_NULOS}}</td>
          <td>{{resumen.TOTAL_VOTOS}}</td>
          <td>{{(resumen.TOTAL_VOTOS / resumen.LISTA_NOMINAL) * 100}}%</td>
        </tr>
      </tbody>
    </table>
</template>
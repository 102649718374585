<script>
import { ref, computed } from 'vue'
export default {
  props :['items'],
  setup(props){
    const names = [...new Set(props.items.map(d => d.nombre))];
    let   name = ref(names[0]);
    const setName = nm => name.value = nm;
    const events = computed( () => props.items.filter( d => d.nombre == name.value) );

    return {
      names, 
      name,
      setName,
      events
    }
  }
}
</script>

<template>
  <h4>eventos</h4>
  <p>
    <button v-for="n in names" :key="'nme-' + n" v-on:click="setName(n)" :class="n == name ? 'current' : ''">
      {{n}}
    </button>
  </p>
<div class="if_wrapper">
   <table class="table if_table">
    <thead>
      <tr>
        <th>Evento</th>
        <th>Descripción</th>
        <th>Lugar</th>
        <th>Fecha</th>
        <th>Horario</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(ev, i) in events" :key="`event-${i}`">
        <td>{{ev.evento}}</td>
        <td>{{ev.descripcion}}</td>
        <td>{{ev.lugar}}</td>
        <td>{{ev.fecha}}</td>
        <td>{{ev.inicio}} - {{ev.fin}}</td>
      </tr>
    </tbody>
  </table>
  </div>
</template>
<script>
import { computed } from 'vue'
import {agregateIncome, incomeList, format} from "@/common-library";

export default {
  props: ["items"],
  setup(props) {
    const income =  computed( () => agregateIncome(props.items));
    const headers  =  computed( () => ["tipo"].concat( income.value.map(d => d.nombre) ))
    return {
      income,
      headers,
      incomeList,
      format
    }
  },
};
</script>
<template>
  <h4>Ingresos</h4>
<div class="if_wrapper">
   <table class="table if_table">
    <thead>
      <tr>
        <th v-for="(hd, i) in headers" :key="`income-${i}`">{{hd}}</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(inc, i) in incomeList" :key="`income-${i}`">
        <td>{{inc.replaceAll("_", " ")}}</td>
        <td v-for="(mono, i) in income" :key="`income-mono-${i}`">
          ${{format(mono.items[inc])}}
        </td>
      </tr>
    </tbody>
  </table>
</div>
</template>
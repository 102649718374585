<script>
export default {
  props: ["parties", "data", "resumen", "order", "type"],
};
</script>
<template>
<div class="if_wrapper">
   <table class="table if_table">
    <thead>
      <tr>
        <th>sección</th>
        <th
          v-for="title in parties"
          :key="title.name"
          :class="title.id == order ? 'selected' : ''"
        >
          {{ title.name }}
        </th>
        <th>votos nulos</th>
        <th>lista nominal</th>
        <th>total votos</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(item, i) in data" :key="i">
        <td>
          <h4>{{ item.section }}</h4>
        </td>
        <td v-for="(formula, j) in parties" :key="j + ',' + i">
          <div v-if="type" class="el_conteiner_graph">
            <div
              class="el_table_graph"
              :style="{ width: item[formula.id + type] + '%' }"
            ></div>
          </div>
          <span :class="formula.id == item.winner ? 'winner' : ''">
            {{ item[formula.id + type] }} <span v-if="type">%</span>
          </span>
          <br />
          <span v-if="type" class="el_notevotes">
            ({{ item[formula.id] }} votos)
          </span>
        </td>
        <td>
          <div v-if="type" class="el_conteiner_graph">
            <div
              class="el_table_graph"
              :style="{ width: item['NUM_VOTOS_NULOS' + type] + '%' }"
            ></div>
          </div>
          {{ item["NUM_VOTOS_NULOS" + type] }} <span v-if="type">%</span>
          <br />
          <span v-if="type" class="el_notevotes">
            ({{ item["NUM_VOTOS_NULOS"] }} votos)
          </span>
        </td>
        <td>
          {{ item["LISTA_NOMINAL" + type] }} <span v-if="type">%</span>
          <span v-if="type">{{ item["LISTA_NOMINAL"] }}</span>
        </td>

        <td>
          <div v-if="type" class="el_conteiner_graph">
            <div
              class="el_table_graph"
              :style="{ width: item['TOTAL_VOTOS' + type] + '%' }"
            ></div>
          </div>
          {{ item["TOTAL_VOTOS" + type] }} <span v-if="type">%</span>
          <br />
          <span v-if="type" class="el_notevotes">
            ({{ item["TOTAL_VOTOS"] }} votos)
          </span>
        </td>
      </tr>
    </tbody>
  </table>
</div>
</template>
<script>
import { computed } from 'vue'
import {agregateExpenses, expenseList, format} from "@/common-library";

export default {
  props: ["items"],
  setup(props) {
    const expenses =  computed( () => agregateExpenses(props.items));
    const headers  =  computed( () => ["tipo"].concat( expenses.value.map(d => d.nombre) ))
    return {
      expenses,
      headers,
      expenseList,
      format
    }
  },
};
</script>
<template>
  <h4>Gastos</h4>
<div class="if_wrapper">
   <table class="table if_table">
    <thead>
      <tr>
        <th v-for="(hd, i) in headers" :key="`expenses-${i}`">{{hd}}</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(expense, i) in expenseList" :key="`expense-${i}`" :class="{ 'tr_total' : expense == 'total_gastos', 'tr_tope' : expense == 'tope_de_gastos'}">
        <td>{{expense.replaceAll("_", " ")}}</td>
        <td v-for="(mono, i) in expenses" :key="`expense-mono-${i}`" class="text-right" :class="{ 'td_zero' : mono.items[expense] == 0}">
          ${{ format(mono.items[expense])}}
        </td>
      </tr>
    </tbody>
  </table>
</div>
</template>
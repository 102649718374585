<script>
import SvgIcon from '@/components/SvgIcon';
export default {
  props : ["parties", "data", "resumen"],
  components : {SvgIcon}
}
</script>
<template>
<div class="if_wrapper">
   <table class="table if_table">
      <thead>
        <tr>
          <th>sección</th>
          <th>casilla</th>
          <th v-for="title in parties" :key="title.name">{{title.name}}</th>
          <th>lista nominal</th>
          <th>votos nulos</th>
          <th>total de votos</th>
          <th>acta</th>

        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in data" :key="`${index}-casilla-item`">
          <td><h4>{{item.seccion}}</h4></td>
          <td>{{item.casilla}}</td>
          <td v-for="(party, j) in parties" :key="j+','+ index">
            {{item[party.id]}}
          </td>
          <td>{{item.LISTA_NOMINAL}}</td> 
          <td>{{item.NUM_VOTOS_NULOS}}</td> 
          <td>{{item.TOTAL_VOTOS}}</td>
          <td> <a v-if="item.acta" :href="item.acta" target="new" class="btn_table"> <svg-icon icon="acta" :hasFill="true"></svg-icon> Acta</a> </td>
        </tr>

        <tr>
          <td>{{resumen.SECCIONES}}</td>
          <td>{{data.length}}</td>

          <td v-for="(party, j) in parties" :key="j+ 'x'">
            {{ resumen[party.id] }}
          </td>

          <td>{{resumen.LISTA_NOMINAL}}</td>
          <td>{{resumen.NUM_VOTOS_NULOS}}</td>
          <td>{{resumen.TOTAL_VOTOS}}</td>
          <td><a :href="resumen.RUTA_ACTA" target="_new" class="btn_table"> <svg-icon icon="acta" :hasFill="true"></svg-icon> Acta</a></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>